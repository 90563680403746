import './App.css';
import MapContainer from './MapContainer';


function App() {
  return (
    <div className="App">
      <MapContainer />
    </div>
  );
}

export default App;
