
import React, { useState } from 'react';
import { handleClientChange, handleCompositionTypeChange, handleStationFromChange, handleStationToChange, handleCargoFromChange, handleCargoToChange, handleEmptyCargoChange, handleLoadedCargoChange  } from './filterHandlers';

const Sidebar = ({ sidebarOpen, selectedStationFrom, stationsFrom, selectedStation, stations, selectedClient,
  clients, selectedCompositionType, CompositionTypes, selectedCargoTo, CargoTo, selectedCargoFrom, CargoFrom,
  isEmptyCargoChecked, isLoadedCargoChecked, clearSelection, toggleSidebar, simpleFilter, setSimpleFilter,
  data, handleSearchSubmit, searchValue, handleSearchChange,
  setSelectedClient, setSelectedCompositionType, setSelectedStationFrom, setSelectedStation, setSelectedCargoFrom,
  setSelectedCargoTo, setIsEmptyCargoChecked, setIsLoadedCargoChecked
  // handleEmptyCargoChange, handleLoadedCargoChange,

}) => {

  const [availableCompositionTypes, setAvailableCompositionTypes] = useState([]);
  const [availableClients, setAvailableClients] = useState([]);
  const [availableStationTo, setAvailableStationTo] = useState([]);
  const [availableStationFrom, setAvailableStationFrom] = useState([]);
  const [availableCargoFrom, setAvailableCargoFrom] = useState([]);
  const [availableCargoTo, setAvailableCargoTo] = useState([]);
 

  // связь полей фильтров
  const handleClientChangeLocal = (e) => {
    handleClientChange(e, data, setSelectedClient, setAvailableCompositionTypes, setAvailableStationTo,
      setAvailableStationFrom, setAvailableCargoFrom, setAvailableCargoTo, selectedStation, selectedCompositionType,
      selectedStationFrom, selectedCargoFrom, selectedCargoTo,
    );
  };

  const handleCompositionTypeChangeLocal = (e) => {
    handleCompositionTypeChange(e, data, setSelectedCompositionType, selectedClient, selectedStationFrom,
      selectedStation, selectedCargoFrom, selectedCargoTo, setAvailableClients, setAvailableStationFrom,
      setAvailableStationTo, setAvailableCargoFrom, setAvailableCargoTo
    );
  };

  const handleStationFromChangeLocal = (e) => {
    handleStationFromChange(e, data, setSelectedStationFrom, selectedClient, selectedCompositionType,
      selectedStation, selectedCargoFrom, selectedCargoTo, setAvailableClients, setAvailableCompositionTypes,
      setAvailableStationTo, setAvailableCargoFrom, setAvailableCargoTo
    );
  };

  const handleStationToChangeLocal = (e) => {
    handleStationToChange(e, data, setSelectedStation, selectedClient, selectedCompositionType,
      selectedStationFrom, selectedCargoFrom, selectedCargoTo, setAvailableCargoTo, setAvailableClients,
      setAvailableCompositionTypes, setAvailableStationFrom, setAvailableCargoFrom
    )
  };

  const handleCargoFromChangeLocal = (e) => {
    handleCargoFromChange(e, data, setSelectedCargoFrom, selectedClient, selectedCompositionType,
      selectedStationFrom, selectedStation, selectedCargoTo, setAvailableCargoTo, setAvailableClients,
      setAvailableCompositionTypes, setAvailableStationFrom, setAvailableStationTo
    )
  };

  const handleCargoToChangeLocal = (e) => {
    handleCargoToChange(e, data, setSelectedCargoTo, selectedClient, selectedCompositionType, selectedStationFrom,
      selectedStation, selectedCargoFrom, setAvailableCargoFrom, setAvailableClients, setAvailableCompositionTypes,
      setAvailableStationFrom, setAvailableStationTo
    )
  };

  const handleEmptyCargoChangeLocal  = (e) => {
    handleEmptyCargoChange(e, data, setIsEmptyCargoChecked, selectedClient, selectedCompositionType,
      selectedStationFrom, selectedCargoTo, selectedStation, selectedCargoFrom, setAvailableCargoFrom, setAvailableClients,
      setAvailableCompositionTypes, setAvailableStationFrom, setAvailableStationTo, setAvailableCargoTo
    )
  };

  const handleLoadedCargoChangeLocal  = (e) => {
    handleLoadedCargoChange(e, data, setIsLoadedCargoChecked, selectedClient, selectedCompositionType,
      selectedStationFrom, selectedCargoTo, selectedStation, selectedCargoFrom, setAvailableCargoFrom, setAvailableClients,
      setAvailableCompositionTypes, setAvailableStationFrom, setAvailableStationTo, setAvailableCargoTo
    )
  };

  return (
    sidebarOpen && (
      <div className="sidebar">
        <button onClick={toggleSidebar} className="closeButton">Х</button>
        <h3 className="title">Фільтри</h3>
        <h3 className="fieldTitle">клієнт:</h3>
        <select id="clientSelect" 
        className="select" 
        value={selectedClient} 
        onChange={handleClientChangeLocal} 
        >
          <option value="">оберіть клієнта</option>
          {((selectedCompositionType || selectedStationFrom || selectedStation || selectedCargoFrom || selectedCargoTo  || isEmptyCargoChecked || isLoadedCargoChecked) ? availableClients : clients).map((client, index) => (
            <option key={`client_${index}`} value={client}>
              {client}
            </option>
          ))}
        </select>
        <h3 className="fieldTitle">тип рухомого складу:</h3>
        <select id="CompositionTypeSelect"
          className="select"
          value={selectedCompositionType}
          onChange={handleCompositionTypeChangeLocal}
        >
          <option value="">оберіть тип рухомого складу</option>
          {((selectedClient || selectedStationFrom || selectedStation || selectedCargoFrom || selectedCargoTo || isEmptyCargoChecked || isLoadedCargoChecked) ? availableCompositionTypes : CompositionTypes).map((compositionType, index) => (
            <option key={`compositionType_${index}`} value={compositionType}>
              {compositionType}
            </option>
          ))}
        </select>
        <h3 className="fieldTitle">станції:</h3>
        <select id="stationFromSelect" 
        className="select" value={selectedStationFrom}
        onChange={handleStationFromChangeLocal}
        >
          <option value="">оберіть станцію відправлення:</option>
          {((selectedClient || selectedCompositionType || selectedStation || selectedCargoFrom || selectedCargoTo || isEmptyCargoChecked || isLoadedCargoChecked) || simpleFilter ? availableStationFrom : stationsFrom).map((stationFrom, index) => (
            <option key={`stationFrom_${index}`} value={stationFrom}>
              {stationFrom}
            </option>
          ))}
        </select>
        <select id="stationSelect"
          className="select"
          value={selectedStation}
          onChange={handleStationToChangeLocal}
        >
          <option value="">оберіть станцію призначення:</option>
          {((selectedClient || selectedStationFrom || selectedCompositionType || selectedCargoFrom || selectedCargoTo || isEmptyCargoChecked || isLoadedCargoChecked) ? availableStationTo : stations).map((stations, index) => (
            <option key={`stations_${index}`} value={stations}>
              {stations}
            </option>
          ))}
        </select>
        <h3 className="fieldTitle">відправник/отримувач:</h3>
        <select id="CargoFrom" className="select" value={selectedCargoFrom} onChange={handleCargoFromChangeLocal} >
          <option value="">оберіть код відправника</option>
          {((selectedClient || selectedStationFrom || selectedStation || selectedCompositionType || selectedCargoTo || isEmptyCargoChecked || isLoadedCargoChecked) ? availableCargoFrom : CargoFrom).map((CargoFrom, index) => (
            <option key={`CargoFrom_${index}`} value={CargoFrom}>
              {CargoFrom}
            </option>
          ))}
        </select>
        <select id="CargoTo" className="select" value={selectedCargoTo} onChange={handleCargoToChangeLocal} >
          <option value="">оберіть код отримувача</option>
          {((selectedClient || selectedStationFrom || selectedStation || selectedCargoFrom || selectedCompositionType || isEmptyCargoChecked || isLoadedCargoChecked) ? availableCargoTo : CargoTo).map((CargoTo, index) => (
            <option key={`CargoTo_${index}`} value={CargoTo}>
              {CargoTo}
            </option>
          ))}
        </select>
        <h3 className="fieldTitle">вагони:</h3>
        <div>
          <input type="text"
            placeholder="пошук по номеру вагону"
            value={searchValue}
            className="inputField"
            onChange={handleSearchChange}
            onKeyPress={handleSearchSubmit}
          />
        </div>
        <div>
          <input
            type="number"
            id="simpleFilterInput"
            value={simpleFilter || ''}
            onChange={(e) => setSimpleFilter(parseInt(e.target.value))}
            className="inputField"
            placeholder="простій більше ніж..."
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div  className="checkbox-container" style={{ marginRight: '20px' }}>
          <input
              type="checkbox"
              id="isEmptyCargoCheckbox"
              checked={isEmptyCargoChecked}
              onChange={handleEmptyCargoChangeLocal}
            />
            <label htmlFor="isEmptyCargoCheckbox" className="fieldTitle" style={{ marginLeft: '5px' }}>порожні</label>
          </div>
          <div className="checkbox-container">
          <input
              type="checkbox"
              id="isLoadedCargoCheckbox"
              checked={isLoadedCargoChecked}
              onChange={handleLoadedCargoChangeLocal}
            />
            <label htmlFor="isLoadedCargoCheckbox" className="fieldTitle" style={{ marginLeft: '5px' }}>завантажені</label>
          </div>
        </div>

        <button onClick={() => { clearSelection() }} className="clearButton">Очистити</button>
      </div>
    )
  );
};

export default Sidebar;