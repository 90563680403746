import * as XLSX from 'xlsx';
import './mapUtils.css';

// Функція для створення вмісту таблиці для спливаючого вікна
export const generateTableContent = (markers) => {
    let tableContent = '<div style="width: 100%; margin: auto; overflow-x: auto; overflow-y: auto; color: black; scrollbar-width: thin;">';
    tableContent += `<table style="border-collapse: collapse; margin: auto; width: 99%;">
    <thead>
        <tr>
            <th>    Вагон №         </th> 
            <th>    Індекс потягу   </th>
            <th>    Ст.код          </th>
            <th>    Факт.ст         </th>
            <th>    Дата операції   </th>
            <th>    Від.код         </th>
            <th>    Ст. відправлення</th>
            <th>    Дата поч. рейсу </th>
            <th>    Вт.від          </th>
            <th>    Вт.отр          </th>
            <th>    Вт.код          </th>
            <th>    Вантаж          </th>
            <th>    Ст.призначення  </th> 
            <th>    Клієнт          </th>           
            <th>    Пустий          </th>         
            <th>    Простій         </th>
            </tr>
    </thead>
    <tbody>`;

    markers.forEach((marker) => {
        const popupContent          = marker.getPopup().getContent();
        const [, railwayNumber]     = popupContent.match(/<span class="valueContent">(.*?)<\/span>/); 
        const [, indexTraine]       = popupContent.match(/Індекс потягу: <span class="valueContent">(.*?)<\/span>/);
        const [, actualStationCode] = popupContent.match(/Поточна станція код: <span class="valueContent">(.*?)<\/span>/);
        const [, stationActual]     = popupContent.match(/Поточна станція: <span class="valueContent">(.*?)<\/span>/);
        const [, dateValue]         = popupContent.match(/Дата операції: <span class="valueContent">(.*?)<\/span>/);
        const [, stationFromIndex]  = popupContent.match(/Станція початку рейсу код: <span class="valueContent">(.*?)<\/span>/);
        const [, stationFrom]       = popupContent.match(/Станція відправлення: <span class="valueContent">(.*?)<\/span>/);
        const [, dateStart]         = popupContent.match(/Дата початку рейсу: <span class="valueContent">(.*?)<\/span>/);
        const [, cargoFrom]         = popupContent.match(/Вантажовідправник: <span class="valueContent">(.*?)<\/span>/);
        const [, cargoTo]           = popupContent.match(/Вантажоотримувач: <span class="valueContent">(.*?)<\/span>/);
        const [, cargoCode]         = popupContent.match(/Вантаж код: <span class="valueContent">(.*?)<\/span>/);
        const [, cargo]             = popupContent.match(/Вантаж: <span class="valueContent">(.*?)<\/span>/);
        const [, stationTo]         = popupContent.match(/Станція призначення: <span class="valueContent">(.*?)<\/span>/);
        const [, client]            = popupContent.match(/Клієнт: <span class="valueContent">(.*?)<\/span>/);    
        const [, isEmpty]           = popupContent.match(/Порожній вагон: <span class="valueContent">(.*?)<\/span>/);
        const [, simple]            = popupContent.match(/Простій: <span class="valueContent">(.*?)<\/span>/);

        const dateObj                = new Date(dateValue);
        const formattedDateValue     = dateObj.toLocaleDateString('uk-UA');
        const formattedTimeValue     = dateObj.toLocaleTimeString('uk-UA');
        const formattedDateTimeValue = `${formattedDateValue} ${formattedTimeValue}`;
        
        const dateStartObj           = new Date(dateStart);
        const formattedDateStart     = dateStartObj.toLocaleDateString('uk-UA');
        const formattedTimeStart     = dateStartObj.toLocaleTimeString('uk-UA');
        const formattedDateTimeStart = `${formattedDateStart} ${formattedTimeStart}`;
        
        tableContent += `
        <tr>
            <td>                             ${railwayNumber}</td>
            <td>                             ${indexTraine}</td>
            <td>                             ${actualStationCode}</td>
            <td>                             ${stationActual}</td>
            <td>                             ${formattedDateTimeValue}</td>
            <td style="text-align: center;"> ${stationFromIndex}</td>
            <td>                             ${stationFrom}</td>
            <td style=" text-align: start;"> ${formattedDateTimeStart}</td>
            <td style=" text-align: start;"> ${cargoFrom}</td>
            <td style=" text-align: center;">${cargoTo}</td>
            <td style=" text-align: center;">${cargoCode}</td>
            <td style=" width: 175px;">      ${cargo}</td>
            <td>                             ${stationTo}</td>
            <td style=" width: 135px">       ${client ? client : 'данні відсутні...'}</td>
            <td style=" text-align: center;">${isEmpty}</td>
            <td style=" text-align: center;">${simple}</td>
            </tr>`;
    });
    tableContent += `</tbody></table></div>`;
  
    return tableContent;
};


  // Функція для створення модального вікна з таблицею
  export const createModal = (tableContent, mapRef) => {
    // блокируем zoom карты
    if (mapRef && mapRef.current) {
      mapRef.current.setMaxZoom(mapRef.current.getZoom());
    }

    let modal = document.createElement('div');
    modal.style.className       ='modal-container';
    modal.style.position        = 'fixed';
    modal.style.top             = '0';
    modal.style.left            = '0';
    modal.style.width           = '100%';
    modal.style.height          = '100%';
    modal.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
    modal.style.display         = 'flex';
    modal.style.justifyContent  = 'center';
    modal.style.alignItems      = 'center';
    modal.style.zIndex          = '1000';

    let content = document.createElement('div');
    content.className           = 'table-container';
    // content.style.backgroundColor = '#D0D0D0';
    content.style.padding       = '5px';
    content.style.maxHeight     = '80vh';
    content.style.width         = '95%';
    content.style.overflowY     = 'auto';
    content.style.fontSize      = '11px';

    let closeButton = document.createElement('button');
    closeButton.innerHTML       = 'X';
    closeButton.className       = 'close-button-table';
    closeButton.addEventListener('click', () => {
    modal.remove();

     // Сбрасываем блокировку zoom
      if (mapRef && mapRef.current) {
        mapRef.current.setMaxZoom(); 
      }
    });

    let exportButton = document.createElement('button');
    exportButton.innerHTML = '<i class="fas fa-file-excel"></i>';
    exportButton.className = 'export-button-table'; 
    exportButton.addEventListener('click', () => {
        exportToExcel(content.querySelector('table'));
    });

    content.innerHTML = tableContent;

    modal.appendChild(closeButton);
    modal.appendChild(exportButton);
    modal.appendChild(content);

    document.body.appendChild(modal);
};


const exportToExcel = (table) => {
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'таблиця.xlsx');
};
  
  // Функція для генерації вмісту спливаючого вікна для маркера
  export const generatePopupContent = (item) => {
    return `
    <div>
      <p><span class="valueContent">${item.RailwayNumber}</span></p> 
      <p>Індекс потягу: <span class="valueContent">${item.IndexTraine}</span></p>
      <p>Поточна станція код: <span class="valueContent">${item.ActualStationCode}</span></p> 
      <p>Поточна станція: <span class="valueContent">${item.StationActual}</span></p>
      <p>Дата операції: <span class="valueContent">${item.DateOperation}</span></p> 
      <p>Станція початку рейсу код: <span class="valueContent">${item.StationFromIndex}</span></p> 
      <p>Станція відправлення: <span class="valueContent">${item.StationFrom}</span></p>
      <p>Дата початку рейсу: <span class="valueContent">${item.DateStart}</span></p> 
      <p>Вантажовідправник: <span class="valueContent">${item.CargoFrom}</span></p>
      <p>Вантажоотримувач: <span class="valueContent">${item.CargoTo}</span></p> 
      <p>Вантаж код: <span class="valueContent">${item.CargoCode}</span></p>
      <p>Вантаж: <span class="valueContent">${item.Cargo}</span></p>
      <p>Станція призначення: <span class="valueContent">${item.StationTo}</span></p>
      <p>Клієнт: <span class="valueContent">${item.Client ? item.Client : 'данні відсутні...'}</span></p>   
      <p>Порожній вагон: <span class="valueContent">${item.Empty ? 'Так' : 'Ні'}</span></p>
      <p>Простій: <span class="valueContent">${item.Simple}</span></p>
    </div>
  `;
  };
  
