export const handleClientChange = (e, data, setSelectedClient, setAvailableCompositionTypes, setAvailableStationTo,
    setAvailableStationFrom, setAvailableCargoFrom, setAvailableCargoTo, selectedStation, selectedCompositionType,
    selectedStationFrom, selectedCargoFrom, selectedCargoTo
) => {
    const selectedClient = e.target.value;
    setSelectedClient(selectedClient);

    const filteredData = data.filter(item =>
        (!selectedClient || item.Client === selectedClient) &&
        (!selectedStation || item.StationTo === selectedStation) &&
        (!selectedCompositionType || item.CompositionType === selectedCompositionType) &&
        (!selectedStationFrom || item.StationFrom === selectedStationFrom) &&
        (!selectedCargoFrom || item.CargoFrom === selectedCargoFrom) &&
        (!selectedCargoTo || item.CargoTo === selectedCargoTo)
    );

    const uniqueCompositionTypes = [...new Set(filteredData.map(item => item.CompositionType))];
    const uniqueStationTo = [...new Set(filteredData.map(item => item.StationTo))];
    const uniqueStationFrom = [...new Set(filteredData.map(item => item.StationFrom))];
    const uniqueCargoFrom = [...new Set(filteredData.map(item => item.CargoFrom))];
    const uniqueCargoTo = [...new Set(filteredData.map(item => item.CargoTo))];

    setAvailableCompositionTypes(uniqueCompositionTypes);
    setAvailableStationTo(uniqueStationTo);
    setAvailableStationFrom(uniqueStationFrom);
    setAvailableCargoFrom(uniqueCargoFrom);
    setAvailableCargoTo(uniqueCargoTo);
};

export const handleCompositionTypeChange = (e, data, setSelectedCompositionType, selectedClient, selectedStationFrom,
    selectedStation, selectedCargoFrom, selectedCargoTo, setAvailableClients, setAvailableStationFrom,
    setAvailableStationTo, setAvailableCargoFrom, setAvailableCargoTo
) => {
    const selectedCompositionType = e.target.value;
    setSelectedCompositionType(selectedCompositionType);

    const filteredData = data.filter(item =>
        (!selectedCompositionType || item.CompositionType === selectedCompositionType) &&
        (!selectedClient || item.Client === selectedClient) &&
        (!selectedStationFrom || item.StationFrom === selectedStationFrom) &&
        (!selectedStation || item.StationTo === selectedStation) &&
        (!selectedCargoFrom || item.CargoFrom === selectedCargoFrom) &&
        (!selectedCargoTo || item.CargoTo === selectedCargoTo)
    );

    const uniqueClients = [...new Set(filteredData.map(item => item.Client))];
    const uniqueStationFrom = [...new Set(filteredData.map(item => item.StationFrom))];
    const uniqueStationTo = [...new Set(filteredData.map(item => item.StationTo))];
    const uniqueCargoFrom = [...new Set(filteredData.map(item => item.CargoFrom))];
    const uniqueCargoTo = [...new Set(filteredData.map(item => item.CargoTo))];

    setAvailableClients(uniqueClients);
    setAvailableStationFrom(uniqueStationFrom);
    setAvailableStationTo(uniqueStationTo);
    setAvailableCargoFrom(uniqueCargoFrom);
    setAvailableCargoTo(uniqueCargoTo);
};

export const handleStationFromChange = (e, data, setSelectedStationFrom, selectedClient, selectedCompositionType,
    selectedStation, selectedCargoFrom, selectedCargoTo, setAvailableClients, setAvailableCompositionTypes,
    setAvailableStationTo, setAvailableCargoFrom, setAvailableCargoTo
) => {
    const selectedStationFrom = e.target.value;
    setSelectedStationFrom(selectedStationFrom);

    const filteredData = data.filter(item =>
        (!selectedStationFrom || item.StationFrom === selectedStationFrom) &&
        (!selectedClient || item.Client === selectedClient) &&
        (!selectedCompositionType || item.CompositionType === selectedCompositionType) &&
        (!selectedStation || item.StationTo === selectedStation) &&
        (!selectedCargoFrom || item.CargoFrom === selectedCargoFrom) &&
        (!selectedCargoTo || item.CargoTo === selectedCargoTo)
    );

    const uniqueClients = [...new Set(filteredData.map(item => item.Client))];
    const uniqueCompositionTypes = [...new Set(filteredData.map(item => item.CompositionType))];
    const uniqueStationTo = [...new Set(filteredData.map(item => item.StationTo))];
    const uniqueCargoFrom = [...new Set(filteredData.map(item => item.CargoFrom))];
    const uniqueCargoTo = [...new Set(filteredData.map(item => item.CargoTo))];

    setAvailableClients(uniqueClients);
    setAvailableCompositionTypes(uniqueCompositionTypes);
    setAvailableStationTo(uniqueStationTo);
    setAvailableCargoFrom(uniqueCargoFrom);
    setAvailableCargoTo(uniqueCargoTo);
};

export const handleStationToChange = (e, data, setSelectedStation, selectedClient, selectedCompositionType,
    selectedStationFrom, selectedCargoFrom, selectedCargoTo, setAvailableCargoTo, setAvailableClients,
    setAvailableCompositionTypes, setAvailableStationFrom, setAvailableCargoFrom
) => {
    const selectedStationTo = e.target.value;
    setSelectedStation(selectedStationTo);

    const filteredData = data.filter(item =>
        (!selectedStationTo || item.StationTo === selectedStationTo) &&
        (!selectedClient || item.Client === selectedClient) &&
        (!selectedCompositionType || item.CompositionType === selectedCompositionType) &&
        (!selectedStationFrom || item.StationFrom === selectedStationFrom) &&
        (!selectedCargoFrom || item.CargoFrom === selectedCargoFrom) &&
        (!selectedCargoTo || item.CargoTo === selectedCargoTo)
    );

    const uniqueClients = [...new Set(filteredData.map(item => item.Client))];
    const uniqueCompositionTypes = [...new Set(filteredData.map(item => item.CompositionType))];
    const uniqueStationFrom = [...new Set(filteredData.map(item => item.StationFrom))];
    const uniqueCargoFrom = [...new Set(filteredData.map(item => item.CargoFrom))];
    const uniqueCargoTo = [...new Set(filteredData.map(item => item.CargoTo))];

    setAvailableCargoTo(uniqueCargoTo);
    setAvailableClients(uniqueClients);
    setAvailableCompositionTypes(uniqueCompositionTypes);
    setAvailableStationFrom(uniqueStationFrom);
    setAvailableCargoFrom(uniqueCargoFrom);
};

export const handleCargoFromChange = (e, data, setSelectedCargoFrom, selectedClient, selectedCompositionType,
    selectedStationFrom, selectedStation, selectedCargoTo, setAvailableCargoTo, setAvailableClients,
    setAvailableCompositionTypes, setAvailableStationFrom, setAvailableStationTo
) => {
    const selectedCargoFrom = e.target.value;
    setSelectedCargoFrom(selectedCargoFrom);

    const filteredData = data.filter(item =>
        (!selectedCargoFrom || item.CargoFrom === selectedCargoFrom) &&
        (!selectedClient || item.Client === selectedClient) &&
        (!selectedCompositionType || item.CompositionType === selectedCompositionType) &&
        (!selectedStationFrom || item.StationFrom === selectedStationFrom) &&
        (!selectedStation || item.StationTo === selectedStation) &&
        (!selectedCargoTo || item.CargoTo === selectedCargoTo)
    );

    const uniqueClients = [...new Set(filteredData.map(item => item.Client))];
    const uniqueCompositionTypes = [...new Set(filteredData.map(item => item.CompositionType))];
    const uniqueStationFrom = [...new Set(filteredData.map(item => item.StationFrom))];
    const uniqueStationTo = [...new Set(filteredData.map(item => item.StationTo))];
    const uniqueCargoTo = [...new Set(filteredData.map(item => item.CargoTo))];

    setAvailableCargoTo(uniqueCargoTo);
    setAvailableClients(uniqueClients);
    setAvailableCompositionTypes(uniqueCompositionTypes);
    setAvailableStationFrom(uniqueStationFrom);
    setAvailableStationTo(uniqueStationTo);
};


export const handleCargoToChange = (e, data, setSelectedCargoTo, selectedClient, selectedCompositionType,
    selectedStationFrom, selectedStation, selectedCargoFrom, setAvailableCargoFrom, setAvailableClients,
    setAvailableCompositionTypes, setAvailableStationFrom, setAvailableStationTo
) => {
    const selectedCargoTo = e.target.value;
    setSelectedCargoTo(selectedCargoTo);

    const filteredData = data.filter(item =>
        (!selectedCargoTo || item.CargoTo === selectedCargoTo) &&
        (!selectedClient || item.Client === selectedClient) &&
        (!selectedCompositionType || item.CompositionType === selectedCompositionType) &&
        (!selectedStationFrom || item.StationFrom === selectedStationFrom) &&
        (!selectedStation || item.StationTo === selectedStation) &&
        (!selectedCargoFrom || item.CargoFrom === selectedCargoFrom)
    );

    const uniqueClients = [...new Set(filteredData.map(item => item.Client))];
    const uniqueCompositionTypes = [...new Set(filteredData.map(item => item.CompositionType))];
    const uniqueStationFrom = [...new Set(filteredData.map(item => item.StationFrom))];
    const uniqueStationTo = [...new Set(filteredData.map(item => item.StationTo))];
    const uniqueCargoFrom = [...new Set(filteredData.map(item => item.CargoFrom))];

    setAvailableCargoFrom(uniqueCargoFrom);
    setAvailableClients(uniqueClients);
    setAvailableCompositionTypes(uniqueCompositionTypes);
    setAvailableStationFrom(uniqueStationFrom);
    setAvailableStationTo(uniqueStationTo);
};

export const handleEmptyCargoChange = (e, data, setIsEmptyCargoChecked, selectedClient, selectedCompositionType,
    selectedStationFrom, selectedCargoTo, selectedStation, selectedCargoFrom, setAvailableCargoFrom, setAvailableClients,
    setAvailableCompositionTypes, setAvailableStationFrom, setAvailableStationTo, setAvailableCargoTo
) => {
    const isEmptyCargoChecked = e.target.checked;
    setIsEmptyCargoChecked(isEmptyCargoChecked);
  
    const filteredData = data.filter(item =>
      (!isEmptyCargoChecked || isEmptyCargoChecked === item.Empty) &&
      (!selectedClient || item.Client === selectedClient) &&
      (!selectedCargoTo || item.CargoTo === selectedCargoTo) &&
      (!selectedCompositionType || item.CompositionType === selectedCompositionType) &&
      (!selectedStationFrom || item.StationFrom === selectedStationFrom) &&
      (!selectedStation || item.StationTo === selectedStation) &&
      (!selectedCargoFrom || item.CargoFrom === selectedCargoFrom) 
    );
  
    const uniqueClients = [...new Set(filteredData.map(item => item.Client))];
    const uniqueCompositionTypes = [...new Set(filteredData.map(item => item.CompositionType))];
    const uniqueStationTo = [...new Set(filteredData.map(item => item.StationTo))];
    const uniqueStationFrom = [...new Set(filteredData.map(item => item.StationFrom))];
    const uniqueCargoFrom = [...new Set(filteredData.map(item => item.CargoFrom))];
    const uniqueCargoTo = [...new Set(filteredData.map(item => item.CargoTo))];
  
    setAvailableClients(uniqueClients);
    setAvailableCompositionTypes(uniqueCompositionTypes);
    setAvailableStationTo(uniqueStationTo);
    setAvailableStationFrom(uniqueStationFrom);
    setAvailableCargoFrom(uniqueCargoFrom);
    setAvailableCargoTo(uniqueCargoTo);
  };

  export const handleLoadedCargoChange = (e, data, setIsLoadedCargoChecked, selectedClient, selectedCompositionType,
    selectedStationFrom, selectedCargoTo, selectedStation, selectedCargoFrom, setAvailableCargoFrom, setAvailableClients,
    setAvailableCompositionTypes, setAvailableStationFrom, setAvailableStationTo, setAvailableCargoTo
) => {
    const isLoadedCargoChecked = e.target.checked;
    setIsLoadedCargoChecked(isLoadedCargoChecked);
  
    const filteredData = data.filter(item =>
      (!isLoadedCargoChecked || isLoadedCargoChecked === item.Empty) &&
      (!selectedClient || item.Client === selectedClient) &&
      (!selectedCargoTo || item.CargoTo === selectedCargoTo) &&
      (!selectedCompositionType || item.CompositionType === selectedCompositionType) &&
      (!selectedStationFrom || item.StationFrom === selectedStationFrom) &&
      (!selectedStation || item.StationTo === selectedStation) &&
      (!selectedCargoFrom || item.CargoFrom === selectedCargoFrom) 
    );
  
    const uniqueClients = [...new Set(filteredData.map(item => item.Client))];
    const uniqueCompositionTypes = [...new Set(filteredData.map(item => item.CompositionType))];
    const uniqueStationTo = [...new Set(filteredData.map(item => item.StationTo))];
    const uniqueStationFrom = [...new Set(filteredData.map(item => item.StationFrom))];
    const uniqueCargoFrom = [...new Set(filteredData.map(item => item.CargoFrom))];
    const uniqueCargoTo = [...new Set(filteredData.map(item => item.CargoTo))];
  
    setAvailableClients(uniqueClients);
    setAvailableCompositionTypes(uniqueCompositionTypes);
    setAvailableStationTo(uniqueStationTo);
    setAvailableStationFrom(uniqueStationFrom);
    setAvailableCargoFrom(uniqueCargoFrom);
    setAvailableCargoTo(uniqueCargoTo);
  };