import L from 'leaflet';

export const searchWagonByRailwayNumber = (data, searchValue, markerClusterGroupRef, mapRef, generatePopupContent, generateTableContent, createModal) => {
  const foundWagons = data.filter(item => item.RailwayNumber === searchValue);

  if (foundWagons.length > 0) {
    const foundMarkerLayers = [];
    foundWagons.forEach((item, index) => {
      const lat = parseFloat(item.Latitude);
      const lng = parseFloat(item.Longitude);

      if (lat && lng) {
        // Ищем существующие маркеры на карте с соответствующими координатами
        markerClusterGroupRef.current.eachLayer(marker => {
          if (marker.getLatLng().lat === lat && marker.getLatLng().lng === lng) {
            foundMarkerLayers.push(marker);
          }
        });
      }
    });

    // Если найдены маркеры, выделяем их на карте и приближаемся к ним
    if (foundMarkerLayers.length > 0) {
      const bounds = L.featureGroup(foundMarkerLayers).getBounds();
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
        mapRef.current.setZoom(20);
      }
    } else {
      alert('Маркеры с указанным номером не найдены.');
    }
  } else {
    alert('Вагон с указанным номером не найден.');
  }
};