import React, { useState, useEffect, useRef } from 'react';
import './HeaderStyles.css';
import axios from 'axios';

const Header = ({ toggleSidebar, onSubmit, toggleTheme, darkTheme, selectedStationCoords, mapRef }) => {
  const apiStations = process.env.REACT_APP_API_STATIONS;
  const [data, setData] = useState(null);
  const [selectedStation, setSelectedStation] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isListOpen, setIsListOpen] = useState(false);
  const dropdownRef = useRef(null);

  // поиск названий станций и установка масштаба
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiStations);
        setData(response.data);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };
  
    if (selectedStationCoords) {
      if (mapRef.current) {
        const { latitude, longitude } = selectedStationCoords;
        mapRef.current.setView([latitude, longitude], 12);
      }
    }
    fetchData();
  }, [selectedStationCoords]);
  

  const handleStationChange = (station) => {
    setSelectedStation(station.StationName);
    setIsListOpen(false);
    if (onSubmit) { // Проверка на наличие onSubmit
      onSubmit({ latitude: station.Latitude, longitude: station.Longitude });
    }
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    setIsListOpen(true);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsListOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onSubmit]);

  const filteredStations = data ? data.filter(station =>
    station.StationName.toLowerCase().includes(searchText.toLowerCase())
  ) : [];

  return (
    <div className="header">
      <h2>Depot77</h2>
      <input type="text" value={searchText} onChange={handleInputChange}
        placeholder="Почніть вводити назву станції"
        className="input"
      />
      {isListOpen && (
        <div ref={dropdownRef} className="filtered-stations">
          {filteredStations.map((station, index) => (
            <div key={index} onClick={() => handleStationChange(station)}>
              {station.StationName}
            </div>
          ))}
        </div>
      )}

      <button className='buttonNightMode' onClick={toggleTheme}>
        {darkTheme ? 'light mode' : 'night mode'}
      </button>
      <button onClick={toggleSidebar} className="button">☰</button>
    </div>
  );
};

export default Header;