import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import axios from 'axios';
import Header from './Header';
import './MapStyles.css';
import Sidebar from './Sidebar';
import { generateTableContent, createModal, generatePopupContent } from './mapUtils';
import { searchWagonByRailwayNumber } from './wagonSearch';

const MapContainer = () => {
  const apiWagons = process.env.REACT_APP_API_WAGONS;
  const [data, setData] = useState(null);
  const [selectedStation, setSelectedStation] = useState('');
  const [selectedStationFrom, setSelectedStationFrom] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedCompositionType, setSelectedCompositionType] = useState('');
  const [selectedCargoTo, setSelectedCargoTo] = useState('');
  const [selectedCargoFrom, setSelectedCargoFrom] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const mapRef = useRef(null);
  const markerClusterGroupRef = useRef(null);
  const [isEmptyCargoChecked, setIsEmptyCargoChecked] = useState(false);
  const [isLoadedCargoChecked, setIsLoadedCargoChecked] = useState(false);
  const [selectedStationCoords, setSelectedStationCoords] = useState(null);
  //простій вагонів 
  const [simpleFilter, setSimpleFilter] = useState(false);
  //поле всього пропозицій
  const [totalMarkers, setTotalMarkers] = useState(0);
  //поле вагони без координат
  const [markersWithoutCoords, setMarkersWithoutCoords] = useState(0);
  //состояние для хранения поиска номеров вагонов
  const [searchValue, setSearchValue] = useState('');
  //состояние хранения изначального масштаба и координат карты 
  const [initialMapView, setInitialMapView] = useState(null);
  // для ночной темы
  const [darkTheme, setDarkTheme] = useState(false);

  //нажатие смены ночной темы 
  const toggleTheme = () => {
    setDarkTheme(prevTheme => !prevTheme);
  };

  // получаем данные с api BAS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiWagons);
        setData(response.data);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };
    fetchData();
  }, []);

  // Сохраняем исходные координаты и масштаб карты при первой загрузке
  useEffect(() => {
    if (mapRef.current && !initialMapView) {
      setInitialMapView(mapRef.current.getBounds());
    }
  }, [mapRef.current]);

  
  useEffect(() => {
    if (data) {
      const map = L.map('map').setView([48.3794, 31.1656], 6);
      mapRef.current = map;

      // смена ночной темы
      const tileLayer = darkTheme ?
        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
          attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, Tiles courtesy of <a href="https://carto.com/">CartoDB</a>',
          subdomains: 'abcd',
          maxZoom: 19,
          className: 'dark-theme-layer'
        }) :
        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
          attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, Tiles courtesy of <a href="https://carto.com/">CartoDB</a>',
          subdomains: 'abcd',
          maxZoom: 19,
        });
      tileLayer.addTo(map);

      map.on('click', () => {
        setSidebarOpen(false);
      });

      const createCustomClusterIcon = (cluster) => {
        return L.divIcon({
          html: `<div><span>${cluster.getChildCount()}</span></div>`,
          className: 'custom-cluster-icon',
          iconSize: [35, 35],
          iconAnchor: [10, 20],
        });
      };

      markerClusterGroupRef.current = L.markerClusterGroup({
        showCoverageOnHover: true,
        spiderfyOnMaxZoom: false,
        iconCreateFunction: createCustomClusterIcon
      });

      // убираем zoom
      markerClusterGroupRef.current.on('clusterclick', (cluster) => {
        const markers = cluster.layer.getAllChildMarkers();
        const tableContent = generateTableContent(markers);
        createModal(tableContent, mapRef);
      });
      map.addLayer(markerClusterGroupRef.current);

      // Счетчик вагонов без координат
      let markersWithoutCoordsCount = 0;

      data.forEach((item, index) => {
        if ((selectedStation && item.StationTo !== selectedStation)
          || (selectedClient && item.Client !== selectedClient)
          || (selectedCompositionType && item.CompositionType !== selectedCompositionType)
          || (selectedCargoTo && item.CargoTo !== selectedCargoTo)
          || (selectedCargoFrom && item.CargoFrom !== selectedCargoFrom)
          || (isEmptyCargoChecked && !item.Empty)
          || (selectedStationFrom && item.StationFrom !== selectedStationFrom)
          || (isLoadedCargoChecked && item.Empty)
          || // простій вагонів
          (simpleFilter && parseInt(item.Simple) <= simpleFilter)) {
          return;
        }
        const lat = parseFloat(item.Latitude);
        const lng = parseFloat(item.Longitude);

        if (!lat || !lng) {
          // Создаем маркер для вагона без координат в Черном море возле Украины
          const blackSeaCoords = [45.0, 31.0];// Координаты Черного моря Украины
          const markerIcon = L.divIcon({
            className: 'custom-marker',
            html: '<div class="marker-icon">1</div>',
            iconSize: [35, 35],
            iconAnchor: [10, 20],
          });

          const marker = L.marker(blackSeaCoords, { icon: markerIcon });
          const popupContent = generatePopupContent(item);
          marker.bindPopup(popupContent);

          marker.on('click', () => {
            const tableContent = generateTableContent([marker]);
            //передаем mapRef для блокировки zoom
            createModal(tableContent, mapRef);
          });
          markerClusterGroupRef.current.addLayer(marker);
          // Увеличиваем счетчик вагонов без координат
          markersWithoutCoordsCount++;
        } else {
          setMarkersWithoutCoords(0);

          const markerIcon = L.divIcon({
            className: 'custom-marker',
            html: '<div class="marker-icon">1</div>',
            iconSize: [35, 35],
            iconAnchor: [10, 20],
          });

          const marker = L.marker([lat, lng], { icon: markerIcon });
          const popupContent = generatePopupContent(item);
          marker.bindPopup(popupContent);

          marker.on('click', () => {
            const tableContent = generateTableContent([marker]);
            //передаем mapRef для блокировки zoom
            createModal(tableContent, mapRef);
          });

          markerClusterGroupRef.current.addLayer(marker);
        }
      });
      // Устанавливаем количество вагонов без координат
      setMarkersWithoutCoords(markersWithoutCoordsCount);

      // поле всього пропозицій
      if (markerClusterGroupRef.current) {
        setTotalMarkers(markerClusterGroupRef.current.getLayers().length);
      }

      return () => {
        map.remove();
      };
    }

  }, [data, selectedStation, selectedStationFrom, selectedClient, selectedCompositionType, selectedCargoTo,
    selectedCargoFrom, isEmptyCargoChecked, isLoadedCargoChecked, simpleFilter, darkTheme]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleEmptyCargoChange = (e) => {
    setIsEmptyCargoChecked(e.target.checked);
  };

  const handleLoadedCargoChange = (e) => {
    setIsLoadedCargoChecked(e.target.checked);
  };
  // поиска вагона по номеру RailwayNumber
  // Создаем обработчик для изменения значения поля ввода
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  // обработчик для нажатия клавиши Enter и выполнения поиска
  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {
      searchWagonByRailwayNumber(data, searchValue, markerClusterGroupRef, mapRef, generatePopupContent, generateTableContent, createModal);
      // searchWagonByRailwayNumber();
      // setSearchValue('');
    }
  };

  //очистка фильтров 
  const clearSelection = () => {
    setSelectedStation('');
    setSelectedStationFrom('');
    setSelectedClient('');
    setSelectedCompositionType('');
    setSelectedCargoTo('');
    setSelectedCargoFrom('');
    setIsEmptyCargoChecked(false);
    setIsLoadedCargoChecked(false);
    setSimpleFilter(false);
    setSearchValue('');
    // Возврат карты к исходному масштабу
    if (initialMapView && mapRef.current) {
      mapRef.current.fitBounds(initialMapView);
    }
  };

  const handleStationCoordsChange = (coords) => {
    setSelectedStationCoords(coords);
  };

  const stations = data ? [...new Set(data.map(item => item.StationTo))] : [];
  const stationFrom = data ? [...new Set(data.map(item => item.StationFrom))] : [];
  const clients = data ? [...new Set(data.map(item => item.Client))].filter(client => client !== '') : [];
  const CompositionTypes = data ? [...new Set(data.map(item => item.CompositionType))].filter(CompositionType => CompositionType !== '') : [];
  const CargoTo = data ? [...new Set(data.map(item => item.CargoTo))].filter(CargoTo => CargoTo !== '') : [];
  const CargoFrom = data ? [...new Set(data.map(item => item.CargoFrom))].filter(CargoFrom => CargoFrom !== '') : [];

  return (
    <div>
      <Header 
        toggleSidebar={toggleSidebar} 
        onSubmit={handleStationCoordsChange} 
        toggleTheme={toggleTheme} 
        darkTheme={darkTheme} 
        selectedStationCoords={selectedStationCoords}
        mapRef={mapRef}
        />

      <Sidebar
        data={data}
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        selectedStationFrom={selectedStationFrom}
        setSelectedStationFrom={setSelectedStationFrom}
        stationsFrom={stationFrom}
        selectedStation={selectedStation}
        setSelectedStation={setSelectedStation}
        stations={stations}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        clients={clients}
        selectedCompositionType={selectedCompositionType}
        setSelectedCompositionType={setSelectedCompositionType}
        CompositionTypes={CompositionTypes}
        selectedCargoTo={selectedCargoTo}
        setSelectedCargoTo={setSelectedCargoTo}
        CargoTo={CargoTo}
        selectedCargoFrom={selectedCargoFrom}
        setSelectedCargoFrom={setSelectedCargoFrom}
        CargoFrom={CargoFrom}
        isEmptyCargoChecked={isEmptyCargoChecked}
        setIsEmptyCargoChecked={setIsEmptyCargoChecked}
        handleEmptyCargoChange={handleEmptyCargoChange}
        isLoadedCargoChecked={isLoadedCargoChecked}
        setIsLoadedCargoChecked={setIsLoadedCargoChecked}
        handleLoadedCargoChange={handleLoadedCargoChange}
        clearSelection={clearSelection}
        // простій вагонів
        simpleFilter={simpleFilter}
        setSimpleFilter={setSimpleFilter}
        //состояние и обработчик для поля поиска номера вагона
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleSearchChange={handleSearchChange}
        handleSearchSubmit={handleSearchSubmit}
      />

      <div className="marker-count">Всього вагонів: {totalMarkers}</div>
      {markersWithoutCoords > 0 && (
        <div className="marker-count-2">Вагони без координат: {markersWithoutCoords}</div>
      )}

      <div id="map"></div>
    </div>
  );
};

export default MapContainer;